.pvtAxisContainer li span.pvtDisabledAttribute {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}

.pvtAxisContainer .filterElement {
    cursor: pointer;
}


.pvtAxisContainer .filterElement span.pvtAttr {
    padding: 0.3em;
}