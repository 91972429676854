#filtersRow {
    width: max-content;
    margin: 0 auto 0em auto;
}

#addRemoveRow {
    max-width: 1496px;
    margin: 0 auto 0em auto;
}

.spacer{
    height: 300px;
    z-index: -1;
    visibility: hidden;
}